import Storage from "@/utils/storage";
import Vue from "vue";
import Vuex from "vuex";

let token = Storage.getItem("token");

if (!token) {
  token = "";
}

let cookieId = Storage.getItem("cookieId");

if (!cookieId) {
  cookieId = "";
}

Vue.use(Vuex);

const SET_TOKEN = "SET_TOKEN";
const DELETE_TOKEN = "DELETE_TOKEN";
const SET_COOKIEID = "SET_COOKIEID";
const DELETE_COOKIEID = "DELETE_COOKIEID";

function cumpotedExpiration() {
  let curDate = new Date();
  let curTamp = curDate.getTime();
  let curWeeHours = new Date(curDate.toLocaleDateString()).getTime() - 1;
  let passedTamp = curTamp - curWeeHours;
  let leftTamp = 24 * 60 * 60 * 1000 - passedTamp;

  return new Date(new Date() * 1 + leftTamp);
}

export default new Vuex.Store({
  state: {
    token: token,
    cookieId: cookieId,
  },
  mutations: {
    [SET_TOKEN](state, payload) {
      state.token = payload;
    },
    [DELETE_TOKEN](state) {
      state.token = "";
    },
    [SET_COOKIEID](state, payload) {
      state.cookieId = payload;
    },
    [DELETE_COOKIEID](state) {
      state.cookieId = "";
    },
  },
  actions: {
    setToken({ commit }, payload) {
      Storage.setItem("token", payload);
      commit("SET_TOKEN", payload);
    },
    deleteToken({ commit }) {
      commit("DELETE_TOKEN");
    },
    setCookieId({ commit }, payload) {
      Storage.setItem("cookieId", payload, {
        expires: cumpotedExpiration(),
      });
      commit("SET_COOKIEID", payload);
    },
    deleteCookieId({ commit }) {
      commit("DELETE_COOKIEID");
    },
  },
  modules: {},
});
