import Vant from "vant";
import "vant/lib/index.css";
import Vue from "vue";
import App from "./App.vue";
import "./assets/styles/base.scss";
import components from "./components";
import router from "./router";
import store from "./store";
import mixin from "./utils/mixin";
import "./utils/rem";
import global from "./views/Global";
Vue.prototype.GLOBAL = global;

Object.keys(components).forEach((key) => {
  Vue.component(components[key].name, components[key]);
});

// import Vconsole from "vconsole";

// let vConsole = new Vconsole();

// Vue.use(vConsole);

// Vue.config.productionTip = false;
Vue.use(Vant);
Vue.mixin(mixin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
    }
  }
  next();
});
