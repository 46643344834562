export default {
  data() {
    return {
      client: this.MixinClientBrowser(),
    };
  },
  methods: {
    MixinClientBrowser() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //ios
        return "ios";
      } else if (/(Android)/i.test(navigator.userAgent)) {
        //android
        return "android";
      }
    },

    isWeixin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },

    setRightBtn(title, url) {
      const { client } = this;
      if (client === "ios") {
        try {
          this.$bridge.callhandler("setRightBtn", { title, url });
        } catch (err) {
          console.log("try---catch-调用IOS客户端方法报错");
        }
      } else if (client === "android") {
        if (window.LoanMarketChina) {
          try {
            window.LoanMarketChina.setRightBtn(title, url);
          } catch (err) {
            console.log("try---catch-调用安卓客户端方法报错");
          }
        }
      }
    },
  },
};
