import SharePop from "./SharePop";
import Button from "./Button";
import BankItem from "./BankItem";
import Result from "./Result";

export default {
  SharePop,
  Button,
  BankItem,
  Result,
};
