<template>
  <div class="u-flex-col u-col-center result-box">
    <img class="result-img" :src="imgUrl" alt="" />
    <div class="result-font">
      {{ title }}
    </div>
    <div class="result-desc">
      {{ desc }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Result",
  props: {
    imgUrl: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.result-box {
  .result-img {
    display: block;
    width: 3rem;
    height: 1.78rem;
    margin-top: 1.44rem;
    margin-bottom: 0.48rem;
  }
  .result-font {
    font-size: 0.36rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #292929;
    line-height: 0.5rem;
    margin-bottom: 0.12rem;
  }
  .result-desc {
    font-size: 0.26rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #b5b5b5;
    line-height: 0.36rem;
  }
}
</style>
