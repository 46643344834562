<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      client: this.MixinClientBrowser(),
      token: this.$store.state.token,
      cookieId: this.$store.state.cookieId,
    };
  },
  created() {
    window["getIosToken"] = (res) => {
      this.token = res;
      this.$store.dispatch("setToken", this.token);
    };
  },
  mounted() {
    this.getToken();
  },
  methods: {
    getToken() {
      const { client, cookieId } = this;

      if (client === "ios") {
        try {
          window.webkit.messageHandlers.getIosToken.postMessage("ios");
        } catch (err) {
          console.log("try---catch-调用IOS客户端方法报错");
        }
      } else if (client === "android") {
        if (window.LoanMarketChina) {
          try {
            this.token = window.LoanMarketChina.getAuthorization();
          } catch (err) {
            console.log("try---catch-调用安卓客户端方法报错");
          }
        }
      }

      if (!cookieId) {
        this.cookieId = this.getUuid();
      }

      this.$nextTick(() => {
        this.$store.dispatch("setToken", this.token);
        this.$store.dispatch("setCookieId", this.cookieId);
      });
    },

    getUuid() {
      let s = [];
      const hexDigits = "0123456789abcdef";
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4";
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
      s[8] = s[13] = s[18] = s[23] = "-";
      return s.join("");
    },

    MixinClientBrowser() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        return "ios";
      } else if (/(Android)/i.test(navigator.userAgent)) {
        return "android";
      }
    },
  },
  watch: {
    $route() {
      document.title = this.$route.meta.title;
      document.getElementById("app").scroll(0, 0);
    },
  },
};
</script>
