<template>
  <div>
    <!-- 银行卡片纵向 -->
    <div class="card-box bor-bot" v-if="direction === 'vertical'">
      <img class="card-big-img" :src="bankInfo.cardImg" alt="" />
      <div class="u-flex u-row-between u-col-center">
        <div class="u-flex">
          <img class="bank-logo" :src="bankInfo.bankLogo" alt="" />
          <span class="bank-name">{{ bankInfo.bankName }}</span>
        </div>
        <div class="u-flex bank-tag" v-if="bankInfo.bankTag">
          <van-tag
            v-for="(item, index) in bankInfo.bankTag"
            :key="index"
            color="#FFF4E6"
            text-color="#FD9A15"
          >
            {{ item }}
          </van-tag>
        </div>
      </div>
      <div class="bank-tips bank-big-tips">
        {{ bankInfo.bankTips }}
      </div>
      <Button class="btn-box" @click="handleClick">
        {{ bankInfo.bankBtnTest }}
      </Button>
    </div>
    <!-- 银行卡片横向 -->
    <div class="card-box card-bor" v-if="direction === 'horizontal'">
      <div class="u-flex u-row-between">
        <img class="card-small-img" :src="bankInfo.cardImg" alt="" />
        <div class="u-flex-1">
          <div class="u-flex">
            <img class="bank-logo" :src="bankInfo.bankLogo" alt="" />
            <span class="bank-name">{{ bankInfo.bankName }}</span>
          </div>
          <div class="bank-tips">
            {{ bankInfo.bankTips }}
          </div>
        </div>
      </div>
      <div class="u-flex u-row-between u-col-center bank-foot">
        <div
          class="u-flex u-flex-wrap u-flex-1 bank-tag bank-small-tag"
          v-if="bankInfo.bankTag"
        >
          <van-tag
            v-for="(item, index) in bankInfo.bankTag"
            :key="index"
            color="#FFF4E6"
            text-color="#FD9A15"
          >
            {{ item }}
          </van-tag>
        </div>
        <Button style="margin-bottom: 0.1rem" @click="handleClick">
          {{ bankInfo.bankBtnTest }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BankItem",
  props: {
    /**
     * bankInfo对象的属性：
     * cardImg：银行卡片
     * bankLogo： 银行logo
     * bankName： 银行名称
     * bankTag：银行标签
     * bankTips： 银行描述
     * bankBtnTest：按钮文案
     */
    bankInfo: {
      type: Object,
      default() {
        return {
          cardImg: null,
          bankLogo: null,
          bankName: "",
          bankTag: [],
          bankTips: "",
          bankBtnTest: "",
        };
      },
    },

    direction: {
      type: String,
      default: "horizontal",
    },
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-box {
  padding: 0.36rem 0.6rem 0.48rem;
  position: relative;
}
.card-big-img {
  display: block;
  width: 100%;
  height: 3.94rem;
  margin-bottom: 0.28rem;
}
.card-small-img {
  display: block;
  width: 2.4rem;
  height: 1.5rem;
  margin-right: 0.2rem;
}
.bank-logo {
  display: inline-block;
  width: 0.34rem;
  height: 0.34rem;
  margin-right: 0.1rem;
}
.bank-name {
  font-size: 0.28rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #3a3a3a;
  line-height: 0.4rem;
}
.bank-tips {
  font-size: 0.24rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: justify;
  color: #9e9e9e;
  line-height: 0.34rem;
  margin-top: 0.1rem;
}
.bank-big-tips {
  margin-top: 0.24rem;
}
.bank-tag {
  ::v-deep {
    .van-tag {
      margin-right: 0.1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.bank-small-tag {
  ::v-deep {
    .van-tag {
      margin-bottom: 0.1rem;
    }
  }
}
.btn-box {
  margin-top: 0.48rem;
}
.bank-foot {
  margin-top: 0.24rem;
}
.card-bor {
  &::after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: 0.6rem;
    bottom: 0;
    left: 0.6rem;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}
</style>
