<template>
  <van-button type="default" round v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </van-button>
</template>

<script>
export default {
  name: "Button",
};
</script>

<style lang="scss" scoped>
button {
  display: block;
  border: 0;
  font-size: 0.26rem;
  text-align: center;
  line-height: 0.4rem;
  width: 1.68rem;
  height: 0.6rem;
  background: linear-gradient(90deg, #ffbb31, #fb8302);
  color: #ffffff;
  &[disabled] {
    background: rgba(216, 216, 216, 1);
    font-family: AlibabaPuHuiTiB;
    font-weight: 600;
    color: #000000;
    letter-spacing: 0;
    box-shadow: 0 10px 20px -8px rgba(216, 216, 216, 1);
  }
  &.btn-box {
    width: 100%;
    height: 0.84rem;
    font-size: 0.28rem;
  }
}
</style>
