import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/register"),
    meta: {
      title: "注册",
    },
  },
  {
    path: "/wallet",
    name: "Wallet",
    component: () => import("../views/wallet"),
    meta: {
      title: "融e钱包",
    },
  },
  {
    path: "/carloan",
    name: "CarLoan",
    component: () => import("../views/carloan"),
    meta: {
      title: "有车贷款",
    },
  },
  {
    path: "/protocol",
    name: "Protocol",
    component: () => import("../views/protocol"),
    meta: {
      title: "协议",
    },
  },
  {
    path: "/certification",
    name: "Certification",
    component: () => import("../views/certification"),
    meta: {
      title: "融e钱包",
    },
  },
  {
    path: "/cardApplication",
    name: "CardApplication",
    component: () => import("../views/cardApplication"),
    meta: {
      title: "信用卡申请",
    },
  },
  {
    path: "/progressQuery",
    name: "ProgressQuery",
    component: () => import("../views/progressQuery"),
    meta: {
      title: "进度查询",
    },
  },
  {
    path: "/404",
    name: "NoPage",
    component: () => import("../views/404"),
    meta: {
      title: "404",
    },
  },
  {
    path: "/carLoanSuccess",
    name: "CarLoanSuccess",
    component: () => import("../views/carLoanSuccess"),
    meta: {
      title: "易鑫车贷",
    },
  },
  {
    path: "/withdrawalSuccess",
    name: "WithdrawalSuccess",
    component: () => import("../views/withdrawalSuccess"),
    meta: {
      title: "取款成功",
    },
  },
  {
    path: "/paymentSuccess",
    name: "PaymentSuccess",
    component: () => import("../views/paymentSuccess"),
    meta: {
      title: "还款成功",
    },
  },
  {
    path: "/productRecomed",
    name: "ProductRecomed",
    component: () => import("../views/productRecomed"),
    meta: {
      title: "产品推荐",
    },
  },
  // 根据产品要求进行产品推荐页的改版
  {
    path: "/productRecomedNew",
    name: "productRecomedNew",
    component: () => import("../views/productRecomedNew"),
    meta: {
      title: "产品推荐",
    },
  },
  // 根据产品要求增加静态动画页 stateWating
  {
    path: "/stateWating",
    name: "stateWating",
    component: () => import("../views/stateWating"),
    meta: {
      title: "产品推荐",
    },
  },
  // 根据产品要求增加静态动画页 stateWatingModel6
  {
    path: "/stateWatingModel6",
    name: "stateWatingModel6",
    component: () => import("../views/stateWatingModel6"),
    meta: {
      title: "产品推荐",
    },
  },

  // 根据产品要求增加静态动画页 stateWatingModel7
  {
    path: "/stateWatingModel7",
    name: "stateWatingModel7",
    component: () => import("../views/stateWatingModel7"),
    meta: {
      title: "产品推荐",
    },
  },
  {
    path: "/stateWatingModelXyb",
    name: "stateWatingModelXyb",
    component: () => import("../views/stateWatingModelXyb"),
    meta: {
      title: "产品推荐",
    },
  },
  {
    path: "/passedSuccess",
    name: "PassedSuccess",
    component: () => import("../views/passedSuccess"),
    meta: {
      title: "下载APP领取免息资格",
    },
  },
  {
    path: "/registerEntry",
    name: "RegisterEntry",
    component: () => import("../views/registerEntry"),
    meta: {
      title: "快速到账 借贷无忧",
    },
  },
  // 模版5跳转
  {
    path: "/registerEntryNew",
    name: "RegisterEntry",
    component: () => import("../views/registerEntry"),
    meta: {
      title: "快速到账 借贷无忧",
    },
  },
  // 模版6跳转
  {
    path: "/registerEntryModel6",
    name: "RegisterEntry",
    component: () => import("../views/registerEntry"),
    meta: {
      title: "快速到账 借贷无忧",
    },
  },
  // 模版7跳转
  {
    path: "/registerEntryModel7",
    name: "RegisterEntryModel7",
    component: () => import("../views/registerEntryModel7"),
    meta: {
      title: "快速到账 借贷无忧",
    },
  },
  // 模版7跳转
  {
    path: "/registerEntryModelXyb",
    name: "registerEntryModelXyb",
    component: () => import("../views/registerEntryModelXyb"),
    meta: {
      title: "快速到账 借贷无忧",
    },
  },
  {
    path: "/contract",
    name: "Contract",
    component: () => import("../views/contract"),
    meta: {
      title: "借款合同",
    },
  },
  {
    path: "/contractInfo",
    name: "ContractInfo",
    component: () => import("../views/contractInfo"),
    meta: {
      title: "",
    },
  },
  {
    path: "/stagingAssessment",
    name: "stagingAssessment",
    component: () => import("../views/stagingAssessment"),
    meta: {
      title: "",
    },
  },
  {
    path: "/stagingAssessment_xyb",
    name: "stagingAssessment_xyb",
    component: () => import("../views/stagingAssessment_xyb"),
    meta: {
      title: "",
    },
  },
  {
    path: "/matchingResults",
    name: "matchingResults",
    component: () => import("../views/model8/matchingResults"),
    meta: {
      title: "匹配结果",
    },
  },
  {
    path: "/apiResults",
    name: "apiResults",
    component: () => import("../views/model8/apiResults"),
    meta: {
      title: "匹配结果",
    },
  },
  {
    path: "/error",
    name: "error",
    component: () => import("../views/error"),
    meta: {
      title: "审核失败",
    },
  },
  {
    path: "/registerEntryModel8",
    name: "registerEntryModel8",
    component: () => import("../views/model8/registerEntryModel8"),
    meta: {
      title: "快速到账 借贷无忧",
    },
  },
  {
    path: "/applyResults",
    name: "applyResults",
    component: () => import("../views/model8/applyResults"),
    meta: {
      title: "",
    },
  },
  {
    path: "/APIApplyResults",
    name: "APIApplyResults",
    component: () => import("../views/model8/APIApplyResults"),
    meta: {
      title: "",
    },
  },
  {
    path: "/stagingAssessmentModel8",
    name: "stagingAssessmentModel8",
    component: () => import("../views/model8/stagingAssessmentModel8"),
    meta: {
      title: sessionStorage.getItem("model") === "model9" ? "安e贷" : "融e钱包",
    },
  },
  {
    path: "/stagingAssessmentHYH",
    name: "stagingAssessmentHYH",
    component: () => import("../views/model8/stagingAssessmentHYH"),
    meta: {
      title: sessionStorage.getItem("model") === "model9" ? "安e贷" : "融e钱包",
    },
  },
  {
    path: "/stateWatingModel8",
    name: "stateWatingModel8",
    component: () => import("../views/model8/stateWatingModel8"),
    meta: {
      title: "产品推荐",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
